import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { AboutUs } from '~/components/About';

const AboutUsPage: React.FC<PageProps> = props => (
  <>
    <SEO title="AboutUS.title" pageContext={props.pageContext} />
    <AboutUs />
  </>
);

export default AboutUsPage;
