import { P as UnstyledP } from '~/components/BaseElements';
import styled from 'styled-components';

export const AnchorLink = styled(UnstyledP)`
  margin: 0;
  padding: 0;
  display: inline-block;
  color: ${props => props.theme.palette.primary.main};
  &:focus,
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
