import React, { useRef } from 'react';
import Hero from '~/components/Hero';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Trans, useTranslation } from 'react-i18next';
import { P, Section, ExternalLink } from '~/components/BaseElements';
import { AnchorLink } from './styles';

export const AboutUs: React.FC = () => {
  const { t } = useTranslation();
  const historyAnchorRef = useRef(null);

  const scrollToHistoryAnchor = () => {
    historyAnchorRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <Section py={0}>
      <Hero isSubPage>{t('AboutUs.pageTitle')}</Hero>
      <Box py={8}>
        <Container>
          <Grid container>
            <Grid item md={8}>
              <P>
                <Trans
                  i18nKey="AboutUs.about"
                  components={{
                    externalLink: <ExternalLink />,
                    historyLink: <AnchorLink onClick={scrollToHistoryAnchor} />,
                  }}
                />
              </P>
              <P>
                <Trans
                  i18nKey="AboutUs.history"
                  components={{
                    historyAnchor: <span ref={historyAnchorRef} />,
                  }}
                />
              </P>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Section>
  );
};
